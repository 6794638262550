import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Card, CardBody, NavLink } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Block from '../Campaigns/components/Newsfeed/Block';
import Select from 'react-select';

class Distributors extends React.Component {
  state = {
    loaded: false,
    loading: false,
    showHelper: true,
    distributors: [],
    brands: [],
    channels: [],
    categories: [],
    revenues: [],
    teams: [],
    search: {},
    loadingResults: false,
    currentDistributor: null,
    page: 1,
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.handleHelperClose = this.handleHelperClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpenDistributor = this.handleOpenDistributor.bind(this);
    this.handleCloseDistributor = this.handleCloseDistributor.bind(this);
  }

  handleHelperClose() {
    this.setState({
      showHelper: false,
    });
    localStorage.setItem('show_helper', 'false');
  }

  handleSelectChange(newValue, actionMeta) {
    const {
      authentication: { user },
    } = this.props;

    this.setState(
      {
        loadingResults: true,
        search: {
          ...this.state.search,
          [actionMeta.name]: newValue ? newValue.value : null,
        },
      },

      () => {
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/distributors/list/`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              search: this.state.search,
              page: 1,
            }
          )
          .then((res) => {
            this.setState({
              loadingResults: false,
              distributors: res.data.content.distributors,
              distributors_from: res.data.content.distributors_from,
              distributors_to: res.data.content.distributors_to,
              distributors_search_total: res.data.content.distributors_total,
            });
          });
      }
    );
  }

  handleInputChange(evt) {
    const {
      authentication: { user },
    } = this.props;

    this.setState(
      {
        loadingResults: true,
        search: {
          ...this.state.search,
          name: evt.target.value,
        },
      },

      () => {
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/distributors/list/`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              search: this.state.search,
              page: 1,
            }
          )
          .then((res) => {
            this.setState({
              loadingResults: false,
              distributors: res.data.content.distributors,
              distributors_from: res.data.content.distributors_from,
              distributors_to: res.data.content.distributors_to,
              distributors_search_total: res.data.content.distributors_total,
            });
          });
      }
    );
  }

  handleOpenDistributor(id) {
    this.props.history.replace({ pathname: `/distributors/${id}` });
    // this.setState({
    //   currentDistributor: this.state.distributors.find((d) => d.id == id),
    // });
  }

  handleCloseDistributor() {
    this.props.history.replace({ pathname: `/distributors` });
    // this.setState({ currentDistributor: null });
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    this.setState({
      showHelper: localStorage.getItem('show_helper') == 'false' ? false : true,
    });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `distributors/list/`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        search: '',
        page: 1,
      })
      .then((res) => {
        this.setState({
          distributors: res.data.content.distributors,
          distributors_from: res.data.content.distributors_from,
          distributors_to: res.data.content.distributors_to,
          distributors_total: res.data.content.distributors_total,
          distributors_total_open: res.data.content.distributors_total_open,
          distributors_total_closed: res.data.content.distributors_total_closed,
          currentDistributor: this.props.match.params.id
            ? res.data.content.distributors.find(
                (d) => d.id == this.props.match.params.id
              )
            : null,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/distributors/filters/list/`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((resx) => {
            this.setState({
              loaded: true,
              loading: false,
              brands: resx.data.content.brands,
              channels: resx.data.content.channels,
              categories: resx.data.content.categories,
              revenues: resx.data.content.revenues,
              teams: resx.data.content.teams,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  render() {
    let {
      loaded,
      loading,
      showHelper,
      distributors,
      distributors_to,
      distributors_from,
      distributors_total,
      distributors_total_open,
      distributors_total_closed,
      currentDistributor,
      brands,
      channels,
      categories,
      revenues,
      teams,
      loadingResults,
      page,
    } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}

        {/* LISTE DE DISTRIBUTEURS */}
        {loaded && !currentDistributor && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Distributeurs en France</h3>
              </Col>
            </Row>

            {/* ZONE 1 : AIDE */}
            {showHelper && (
              <Row className="as-feed as-for-distributors">
                <Col
                  md={12}
                  lg={12}
                  xl={12}
                  className="as-wrapper as-for-distributors"
                >
                  <Block
                    isOpen={true}
                    block={{
                      title:
                        'Bienvenue dans le moteur de recherche des distributeurs en France',
                      content:
                        'Cette application vous permet de découvrir tous les distributeurs de vins et spiritueux en France, et de les filtrer selon les critères de votre choix.',
                      cta_text: 'Prendre rendez-vous',
                      cta_link:
                        'https://calendly.com/team-bottl/echange-avec-chloe?hide_gdpr_banner=1',
                    }}
                    showNoPictureAtAll={true}
                    hideMinimizer={true}
                    onClose={this.handleHelperClose}
                  />
                </Col>
              </Row>
            )}

            {/* ZONE 2 : MÉTRIUES */}
            <Row className="px-0 w-100 d-flex col-md-12 mr-0 ">
              {/* 1 */}
              <Col md={12} xl={4} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__card-widget">
                    <div>
                      <div className="card__title">
                        <h5 className="bold-text">Distributeurs</h5>
                      </div>
                      <div className="dashboard__total">
                        <p className="dashboard__total-stat">
                          {distributors_total} distributeurs
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* 2 */}
              <Col md={12} xl={4} lg={6} xs={12}>
                <Card>
                  <CardBody className="dashboard__card-widget">
                    <div>
                      <div className="card__title">
                        <h5 className="bold-text">Distributeurs ouverts</h5>
                      </div>
                      <div className="dashboard__total">
                        <p className="dashboard__total-stat">
                          {distributors_total_open} distributeurs
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* 3 */}
              <Col md={12} xl={4} lg={6} xs={12} className="pr-0">
                <Card>
                  <CardBody className="dashboard__card-widget">
                    <div>
                      <div className="card__title">
                        <h5 className="bold-text">Distributeurs fermés</h5>
                      </div>
                      <div className="dashboard__total">
                        <p className="dashboard__total-stat">
                          {distributors_total_closed} distributeurs
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <hr className="mt-0" />
            {/* ZONE 3 : CHAMPS DE RECHERCHE */}
            <Row>
              <div className="as-distributors-search-fields">
                <Select
                  placeholder="Type"
                  name="type"
                  options={[
                    { value: 'ouvert', label: 'Ouvert' },
                    { value: 'fermé', label: 'Fermé' },
                  ]}
                  isClearable={true}
                  onChange={this.handleSelectChange}
                />
                <Select
                  placeholder="Canal"
                  name="strongChannel"
                  options={channels.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  isClearable={true}
                  onChange={this.handleSelectChange}
                />
                <Select
                  placeholder="Catégories fortes"
                  isMulti={true}
                  name="strongCategories"
                  options={categories.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  isClearable={true}
                  onChange={this.handleSelectChange}
                />
                <Select
                  placeholder="Tranche de CA"
                  name="revenue_range"
                  options={revenues.map((r) => ({
                    value: r.id,
                    label: r.name,
                  }))}
                  isClearable={true}
                  onChange={this.handleSelectChange}
                />
                <Select
                  placeholder="Marque"
                  name="brand"
                  options={brands.map((b) => ({ value: b.id, label: b.name }))}
                  isClearable={true}
                  onChange={this.handleSelectChange}
                />

                <div className="as-distributors-search-bar-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>

                  <input
                    type="text"
                    name="name"
                    placeholder="Rechercher"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </Row>

            {/* ZONE 4 : RÉSULTATS */}
            {!loadingResults && (
              <>
                {distributors.length > 0 && (
                  <Row className="as-distributors-results">
                    {distributors.map((d) => (
                      <div
                        key={`${d.id}_${d.name}`}
                        className="as-distributor"
                        onClick={() => {
                          this.handleOpenDistributor(d.id);
                        }}
                      >
                        <div className="as-header">
                          <div className="as-left">
                            <div className="as-icon-wrapper">
                              {!d.logo && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                                  />
                                </svg>
                              )}
                              {d.logo && (
                                <img
                                  src={`${process.env.REACT_APP_WEBROOT}/images/distributeurs/${d.id}/${d.logo}`}
                                  alt={d.name}
                                />
                              )}
                            </div>
                            <span>
                              {d.name.length > 30
                                ? d.name.slice(0, 26) + '...'
                                : d.name}
                            </span>
                          </div>
                        </div>
                        <div className="as-body">
                          <p>
                            {d.description
                              ? d.description.slice(0, 100)
                              : 'Aucune description renseignée pour ce distributeur pour le moment. Lorem ipsum dolor sit amet consecutem amor'}
                          </p>
                        </div>
                        <div className="as-footer">
                          <div className="as-cols">
                            <div className="as-metric">
                              <span className="as-label">Tranche de CA</span>
                              <span className="as-value">
                                {revenues.some((r) => r.id == d.revenue_range)
                                  ? revenues.find(
                                      (r) => r.id == d.revenue_range
                                    ).name
                                  : 'Non renseigné'}
                              </span>
                            </div>
                            <div className="as-metric">
                              <span className="as-label">Marques</span>
                              <span className="as-value">
                                {d.brands_count || 'Aucune'}
                              </span>
                            </div>
                            <div className="as-metric">
                              <span className="as-label">Création</span>
                              <span className="as-value">
                                {d.creation_date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Row>
                )}
                {distributors.length === 0 && (
                  <div className="as-distributors-empty-placeholder">
                    <p>Aucun résultat trouvé pour votre recherche</p>
                  </div>
                )}
              </>
            )}
            {loadingResults && (
              <div className="as-distributors-loading-placeholder">
                <div class="load__icon-wrap">
                  <svg class="load__icon">
                    <path
                      fill="#5b7da5"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    ></path>
                  </svg>
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        {/* DISTRIBUTEUR UNIQUE */}
        {loaded && currentDistributor && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <button
                  className="as-back-button"
                  onClick={this.handleCloseDistributor}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                    />
                  </svg>
                </button>

                <h3 className="page-title">Profil du distributeur</h3>
              </Col>
            </Row>

            <Row>
              {/* GAUCHE */}
              <Col md={7}>
                {/* ZONE - LOGO + NOM */}
                <div className="as-single-distributor as-for-header">
                  <div className="as-left">
                    <div className="as-icon-wrapper">
                      {!currentDistributor.logo && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                          />
                        </svg>
                      )}
                      {currentDistributor.logo && (
                        <img
                          src={`${process.env.REACT_APP_WEBROOT}/images/distributeurs/${currentDistributor.id}/${currentDistributor.logo}`}
                          alt={currentDistributor.name}
                        />
                      )}
                    </div>
                  </div>
                  <div className="as-right">
                    <span className="as-distributor-name">
                      {currentDistributor.name}
                    </span>
                    <span className="as-distributor-address">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>

                      {currentDistributor.address || 'Adresse non renseignée'}
                    </span>
                  </div>
                </div>

                {/* ZONE - DESCRIPTION + MARQUES */}
                <div className="as-single-distributor as-for-presentation">
                  <h3>Présentation</h3>
                  <p>
                    {currentDistributor.description ||
                      'Aucune description renseignée'}
                  </p>
                  <h3>Marques distribuées</h3>
                  {currentDistributor.brands.length === 0 && (
                    <p>Aucune marque renseignée</p>
                  )}
                  {currentDistributor.brands.length > 0 && (
                    <div className="as-single-distributor as-for-brands">
                      {currentDistributor.brands
                        .map((b) => brands.find((_b) => _b.id == b).name)
                        .map((b) => (
                          <div key={b} className="as-brand-pill">
                            {b}
                          </div>
                        ))}
                    </div>
                  )}
                  <h3>Liens pertinents</h3>
                  {currentDistributor.blog_links.length === 0 && (
                    <p>Aucun lien renseigné</p>
                  )}
                  {currentDistributor.blog_links.length > 0 && (
                    <ul>
                      {currentDistributor.blog_links.map((l) => (
                        <li key={l}>
                          <a href={l} target="_blank">
                            {l}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Col>
              {/* DROITE */}
              <Col md={5}>
                {/* ZONE - CEO */}
                <div className="as-single-distributor as-for-ceo">
                  <h3>Gérant</h3>
                  <div class="as-cols">
                    <div className="as-icon-wrapper">
                      {!currentDistributor.ceo_photo && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                      )}
                      {currentDistributor.ceo_photo && (
                        <img
                          src={`${process.env.REACT_APP_WEBROOT}/images/distributeurs/${currentDistributor.id}/${currentDistributor.ceo_photo}`}
                          alt={currentDistributor.ceo_photo}
                        />
                      )}
                    </div>
                    <span>
                      {currentDistributor.ceo_first_name &&
                      currentDistributor.ceo_last_name
                        ? `${currentDistributor.ceo_first_name} ${currentDistributor.ceo_last_name}`
                        : `Gérant inconnu`}
                    </span>
                  </div>
                </div>

                {/* ZONE - POINTS CLÉS */}
                <div className="as-single-distributor as-for-metrics">
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                      />
                    </svg>
                    Points clés
                  </h3>
                  <div className="as-metrics">
                    <div className="as-metric">
                      <span className="as-label">Date de création</span>
                      <span className="as-value">
                        {currentDistributor.creation_date}
                      </span>
                    </div>
                    <div className="as-metric">
                      <span className="as-label">Équipe dédiée</span>
                      <span className="as-value">
                        {teams.some((t) => t.id == currentDistributor.team_size)
                          ? teams.find(
                              (t) => t.id == currentDistributor.team_size
                            ).name +
                            (currentDistributor.team_size != 0
                              ? ' collaborateurs'
                              : '')
                          : 'Non renseigné'}
                      </span>
                    </div>
                    <div className="as-metric">
                      <span className="as-label">Revenus</span>
                      <span className="as-value">
                        {revenues.some(
                          (r) => r.id == currentDistributor.revenue_range
                        )
                          ? revenues.find(
                              (r) => r.id == currentDistributor.revenue_range
                            ).name
                          : 'Non renseigné'}
                      </span>
                    </div>
                  </div>
                </div>

                {/* ZONE - SEGMENTATION */}
                <div className="as-single-distributor as-for-metrics">
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                      />
                    </svg>
                    Segmentation de marché
                  </h3>
                  <div className="as-metrics">
                    <div className="as-metric">
                      <span className="as-label">Type</span>
                      <span className="as-value">
                        {currentDistributor.type
                          ? currentDistributor.type.charAt(0).toUpperCase() +
                            currentDistributor.type.slice(1)
                          : 'Non renseigné'}
                      </span>
                    </div>
                    <div className="as-metric">
                      <span className="as-label">Canal fort</span>
                      <span className="as-value">
                        {channels.some(
                          (c) => c.id == currentDistributor.strong_channel
                        )
                          ? channels.find(
                              (c) => c.id == currentDistributor.strong_channel
                            ).name
                          : 'Non renseigné'}
                      </span>
                    </div>
                    <div className="as-metric">
                      <span className="as-label">Catégories</span>
                      <span className="as-value">
                        {currentDistributor.strong_categories.length === 0
                          ? 'Non renseigné'
                          : currentDistributor.strong_categories
                              .map(
                                (c) => categories.find((_c) => _c.id == c).name
                              )
                              .join(', ')}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Distributors);
